<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card
          :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
          style="min-height: 60vh"
        >
          <h4 v-if="!cadastro" slot="header" class="card-title text-center">
            Edição de Fazendas
          </h4>
          <h4 v-else slot="header" class="card-title text-center">
            Cadastro de Fazendas
          </h4>
          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="action"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados da Fazenda
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.inscricao_estadual"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Inscrição Estadual"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.nome_fantasia"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Nome Fantasia *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="form.porte"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        :items="portes"
                        item-text="text"
                        item-value="value"
                        label="Porte "
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.produtor"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Produtor *"
                        :items="produtores"
                        :rules="requiredRules"
                        item-text="name"
                        item-value="id"
                        :disabled="!loadingData.produtor"
                        :loading="!loadingData.produtor"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.consultor"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Consultor *"
                        :items="consultor"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="id"
                        :disabled="!loadingData.consultor"
                        :loading="!loadingData.consultor"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.agroindustria"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Agroindustria *"
                        :items="agroindustrias"
                        :rules="requiredRules"
                        item-text="name"
                        item-value="id"
                        :disabled="!loadingData.agroindustria"
                        :loading="!loadingData.agroindustria"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.razao_social"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Razão Social"
                        :items="nirf"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.cnpj"
                        v-mask="maskCnpj"
                        label="CNPJ"
                        return-masked-value
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.nirf"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="NIRF"
                        :items="nirf"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.email"
                        type="email"
                        label="E-mail"
                        return-masked-value
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.referencia"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Referência "
                        :items="referencia"
                        required
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.femeas"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Número de femeas *"
                        :items="femeas"
                        :rules="requiredRules"
                        type="number"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.matriculas"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Matrícula "
                        :items="matriculas"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.telefone_residencial"
                        v-mask="maskTelCel"
                        :rules="[...requiredRules, ...telRules]"
                        label="Telefone Comercial *"
                        type="tel"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.telefone"
                        v-mask="maskTelCel"
                        :rules="[...requiredRules, ...telRules]"
                        label="Telefone Celular *"
                        type="tel"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-switch
                        v-model="form.Excluida"
                        label="Cadastro ativo no sistema Esteio"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Endereço
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.estado"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Estado *"
                        :items="estado"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="CodEstado"
                        :loading="!loadingData.estado"
                        :disabled="!loadingData.estado"
                        required
                        @change="changeCidade"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.cidade"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Cidade *"
                        :items="cidade"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="CodCidade"
                        :loading="!loadingData.cidade"
                        :disabled="!loadingData.cidade"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.logradouro"
                        label="Logradouro *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.numero"
                        label="Numero *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.bairro"
                        label="Bairro *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.complemento"
                        label="Complemento *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.cep"
                        v-mask="maskCep"
                        type="text"
                        :rules="cepRules"
                        label="CEP *"
                        required
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="form.latitude"
                        label="Latitude *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="form.longitude"
                        label="Longitude *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.CodFazenda ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>
              <v-btn
                :disabled="salvando"
                color="error"
                class="ml-3"
                @click="
                  () =>
                    this.$router.push({
                      path: `/${this.$user.get().role}/fazendas`,
                    })
                "
              >
                Cancelar
              </v-btn>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import { _orderByName } from '../../utils/formatter'
import { validarCNPJ } from '../../utils/validacoes'

export default {
  data() {
    return {
      edit: false,
      salvando: false,
      loadingData: {
        produtor: false,
        agroindustria: false,
        consultor: false,
        estado: false,
        cidade: false,
      },
      loading: false,
      maskCnpj: '##.###.###/####-##',
      maskTelRes: '(##) ####-####',
      maskTelCel: '(##) # ####-####',
      maskCep: '##.###-###',
      cepRules: [
        v => !!v || 'CEP é obrigatório',
        v => v?.length >= 10 || 'CEP inválido',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      emailRules: [v => /.+@.+/.test(v) || 'E-mail invalido'],
      telRules: [
        v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
      ],
      cnpjRules: [
        v => !!v || '* Obrigatório',
        v => validarCNPJ(v) || '* CNPJ inválido',
      ],
      valid: null,
      disableCidade: true,
      btnNome: !this.$route.query.CodFazenda ? 'Salvar' : 'Salvar Alterações',
      portes: [
        {
          text: 'Micro',
          value: 'Micro',
        },
        {
          text: 'Pequeno',
          value: 'Pequeno',
        },
        {
          text: 'Médio',
          value: 'Médio',
        },
        {
          text: 'Grande',
          value: 'Grande',
        },
      ],
      form: {
        latitude: '',
        longitude: '',
        femeas: '',
        porte: '',
        matriculas: '',
        referencia: '',
        nirf: '',
        cnpj: '',
        email: '',
        inscricao_estadual: '',
        produtor: '',
        consultor: '',
        agroindustria: '',
        razao_social: '',
        estado: 0,
        cidade: 0,
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        telefone_residencial: '',
        telefone: '',
        tipo: 1,
        Excluida: false,
      },

      latitude: '',
      longitude: '',
      femeas: 0,
      matriculas: 0,
      referencia: '',
      nirf: '',
      produtores: [{ name: 'Carregando', id: 0 }],
      consultor: [{ Nome: 'Carregando', id: 0 }],
      agroindustrias: [{ name: 'Carregando', id: 0 }],
      estado: [{ Nome: 'Carregando', CodEstado: 0 }],
      cidade: [],
      cadastro: this.$route.query.CodFazenda,
    }
  },

  created() {
    this.cadastro = this.$route.query.CodFazenda

    if (!this.$route.query.CodFazenda) {
      this.loadNewForm()
      this.loadAgroindustrias()
      this.loadConsultores()
      this.loadProdutores()
    } else {
      this.loadOldForm()
    }
  },

  methods: {
    getForm() {
      const data = {
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        femeas: this.form.femeas,
        porte: this.form.porte,
        matriculas: this.form.matriculas,
        referencia: this.form.referencia,
        nirf: this.form.nirf,
        cnpj: this.form.cnpj,
        email: this.form.email,
        nome_fantasia: this.form.nome_fantasia,
        inscricao_estadual: this.form.inscricao_estadual,
        produtor: this.form.produtor,
        consultor: this.form.consultor,
        agroindustria: this.form.agroindustria,
        razao_social: this.form.razao_social,
        estado: this.form.estado,
        cidade: this.form.cidade,
        bairro: this.form.bairro,
        logradouro: this.form.logradouro,
        numero: this.form.numero,
        complemento: this.form.complemento,
        cep: this.form.cep,
        telefone_residencial: this.form.telefone_residencial,
        telefone: this.form.telefone,
        tipo: 1,
        Excluida: !this.form.Excluida,
      }

      return data
    },

    action() {
      if (!this.$route.query.CodFazenda) {
        this.create()
      } else {
        this.update()
      }
    },

    update() {
      this.salvando = true
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        this.salvando = false
        return false
      }

      const data = this.getForm()

      try {
        this.api.update
          .fazenda(this.cadastro, {
            ...data,
          })
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Fazenda atualizada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.$router.push({
                path: `/${this.$user.get().role}/fazendas`,
              })
            })
          })
          .catch(() => {
            this.salvando = !this.salvando
          })
      } catch (error) {
        this.salvando = !this.salvando
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao atualizar a fazenda.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    async loadOldForm() {
      this.loading = true

      try {
        this.api.get.fazenda(this.cadastro).then(res => {
          this.form.porte = res.Porte
          this.form.cnpj = res.pessoajuridica?.Cnpj
          this.form.email = res.pessoa?.email
          this.form.telefone = res.pessoa?.TelCel
          this.form.telefone_residencial = res.pessoa?.TelRes
          this.form.inscricao_estadual = res?.InscricaoEstadual
          this.form.nirf = res?.Nirf

          this.form.produtor = res?.CodProdutor
          this.form.consultor = res?.CodConsultorTecnico

          this.form.agroindustria =
            res.agroindustria_ativa?.pessoajuridica_agroindustria.CodPessoaJuridica

          this.form.razao_social = res?.pessoajuridica.RazaoSocial
          this.form.nome_fantasia = res?.pessoajuridica.NomeFantasia
          this.form.cidade = res.pessoa?.endereco?.cidade.CodCidade
          this.form.estado = res.pessoa?.endereco?.cidade.estado.CodEstado

          this.form.bairro = res.pessoa?.endereco?.Bairro
          this.form.logradouro = res.pessoa?.endereco?.Logradouro
          this.form.numero = res.pessoa?.endereco?.Numero
          this.form.complemento = res.pessoa?.endereco?.Complemento
          this.form.cep = res.pessoa?.endereco?.Cep
          this.form.latitude = res.Latitude
          this.form.longitude = res.Longitude
          this.form.femeas = res.NumeroFemeas
          this.form.matriculas = res.Matricula
          this.form.referencia = res.Referencia
          this.form.Excluida = !(res.Excluida === '1' ? true : false)

          this.loadAgroindustrias()
          this.loadConsultores()
          this.loadProdutores()
          this.loadEstado()
          this.loadCidade(res.pessoa?.endereco?.cidade.CodEstado)
          this.loading = false
        })
      } catch (error) {
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao carregar a fazenda.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    create() {
      this.salvando = true
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        this.salvando = false
        return false
      }

      const data = this.getForm()

      try {
        this.api.save
          .fazendas(data)
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Cadastro Realizado',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })
              .then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/fazendas`,
                })
              })
              .catch(() => {
                this.salvando = !this.salvando
              })
          })
          .catch(() => {
            this.salvando = !this.salvando
          })
      } catch (error) {
        this.salvando = false
      }
    },

    loadNewForm() {
      this.loadEstado()
      this.loading = false
    },

    loadEstado() {
      this.loadingData.estado = false
      this.api.get.estados().then(data => {
        this.estado = data
        this.loadingData.estado = true
      })
    },

    loadCidade(CodEstado) {
      this.loadingData.cidade = false
      this.api.get.cidades(CodEstado).then(data => {
        this.cidade = data
        this.loadingData.cidade = true
      })
    },

    changeCidade() {
      this.loadCidade(this.form.estado)
      this.form.cidade = ''
    },

    loadAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            name: item.pessoa.pessoajuridica.NomeFantasia,
            id: item.CodAgroindustria,
          }
        })

        this.agroindustrias = _orderByName({
          data: this.agroindustrias,
          key: 'name',
        })

        if (this.agroindustrias.length) {
          this.agroindustrias.unshift({ name: '-- Selecione --', id: '0' })
        }

        this.loadingData.agroindustria = true
      })
    },

    loadConsultores() {
      this.api.get.consultores(1).then(data => {
        this.consultor = data

        this.consultor = _orderByName({
          data,
          key: 'Nome',
        })

        if (this.consultor.length) {
          this.consultor.unshift({ Nome: '-- Selecione --', id: '0' })
        }

        this.loadingData.consultor = true
      })
    },

    loadProdutores() {
      this.api.get.produtores().then(data => {
        this.produtores = data

        this.produtores = _orderByName({
          data,
          key: 'name',
        })

        if (this.produtores.length) {
          this.produtores.unshift({ name: '-- Selecione --', id: '0' })
        }

        this.loadingData.produtor = true
      })
    },
  },
}
</script>
<style>
html {
  overflow: auto !important;
}

input[type='file'] {
  display: none;
}

#foto {
  background-color: #3498db;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 6px 20px;
}

.index {
  z-index: 1;
}
</style>
